import React from "react"
import Moment from 'react-moment'

import Layout from "../components/Layout"
import SEO from "../components/seo"
import ItemBlock from "../components/shared/ItemBlock"
import style from "./news.module.scss"

const posts = [
  {
    id: "",
    title:"",
    body: "",
    url: "",
    date: new Date("January 22, 2020")
  }
]
const NewsPage = () => (
  <Layout>
    <SEO title="Gigs" />
    <ItemBlock>
      <div className={style.home}>
        <h1>News Page</h1>
        <p>This page is under construction, check back soon...</p>
      </div>
    </ItemBlock>
  </Layout>
)

export default NewsPage
